var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "d-flex flex-column", staticStyle: { height: "100%" } },
    [
      _c("div", {
        staticClass: "m-last-login",
        staticStyle: { "text-align": "right" },
      }),
      _c(
        "v-row",
        { staticClass: "mt-5 flex-grow-0", attrs: { justify: "center" } },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-column justify-center align-center m-user-greeting",
            },
            [
              _c("v-avatar", { attrs: { color: "primary", size: "100" } }, [
                _c("span", { staticClass: "white--text display-2" }, [
                  _vm._v(_vm._s(_vm.user.name[0])),
                ]),
              ]),
              _c("div", { staticClass: "headline font-weight-medium mt-4" }, [
                _vm._v(" Welcome, " + _vm._s(_vm.user.name) + " "),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "d-flex flex-grow-1 align-center" },
        _vm._l(_vm.services, function (service) {
          return _c("service-card", {
            key: service.service_id,
            attrs: { service: service },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }