<template>
  <div class="service-card pa-5">
    <v-container>
      <v-row>
        <v-col cols="5" class="d-flex flex-column justify-center align-center">
          <v-avatar color="#f7f7f7" size="68">
            <v-avatar color="#cef0ff" size="54">
              <span class="primary--text font-weight-bold">{{
                serviceInitial
              }}</span>
            </v-avatar>
          </v-avatar>
          <div class="font-weight-bold title text-center mt-3 primary--text">
            {{ service.name }}
          </div>
          <span class="service-card__underline mt-3"></span>
        </v-col>
        <v-col cols="7">
          <p class="text-xl-justify">
            {{ service.description }}
          </p>
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-btn
          rounded
          color="primary"
          v-for="(view, i) in service.views"
          :key="i"
          class="ml-3"
          :href="view.view_url"
          >as {{ view.view_name }}</v-btn
        >
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ServiceCard",
  props: {
    service: {
      type: Object,
      required: true
    }
  },
  computed: {
    serviceInitial() {
      return this.service.name.replace(/\W*(\w)\w*/g, "$1").toUpperCase();
    }
  }
};
</script>

<style lang="scss">
@import "/global/styles/scss/variables";

.service-card {
  width: 613px;
  box-shadow: 3px 3px 4px rgba(91, 92, 94, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-radius: 0.5rem;
}

.service-card__underline {
  background-color: $primary;
  width: 100px;
  height: 2px;
  display: inline-block;
}
</style>
