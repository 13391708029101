<template>
  <v-container class="d-flex flex-column" style="height: 100%">
    <div style="text-align: right" class="m-last-login">
      <!--      Last Login: {{ user.last_login_time }}-->
    </div>
    <v-row justify="center" class="mt-5 flex-grow-0">
      <div
        class="d-flex flex-column justify-center align-center m-user-greeting"
      >
        <v-avatar color="primary" size="100">
          <span class="white--text display-2">{{ user.name[0] }}</span>
        </v-avatar>
        <div class="headline font-weight-medium mt-4">
          Welcome, {{ user.name }}
        </div>
      </div>
    </v-row>

    <div class="d-flex flex-grow-1 align-center">
      <service-card
        v-for="service in services"
        :key="service.service_id"
        :service="service"
      ></service-card>
    </div>
  </v-container>
</template>

<script>
import ServiceCard from "@af/modules/service/ServiceCard";
import authService from "@af/modules/auth/services/AuthService";
import store from "@af/app-shell/store";
export default {
  name: "MServices",
  components: { ServiceCard },
  async created() {
    this.$store.dispatch(this.$store.$types.FETCH_SERVICES);
    await this.user();
  },
  computed: {
    services() {
      return this.$store.getters[this.$store.$types.GET_SERVICES];
    }
  },
  methods: {
    async user() {
      // return this.$store.getters[this.$store.$types.GET_USER_DETAILS];
      return await authService.getUser(this.$store.state.auth.token);
    }
  }
};
</script>

<style scoped>
.m-user-greeting {
  position: relative;
  left: -128px;
}
.m-last-login {
  font-family: "Poppins", sans-serif;
}
</style>
