var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "service-card pa-5" },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex flex-column justify-center align-center",
                  attrs: { cols: "5" },
                },
                [
                  _c(
                    "v-avatar",
                    { attrs: { color: "#f7f7f7", size: "68" } },
                    [
                      _c(
                        "v-avatar",
                        { attrs: { color: "#cef0ff", size: "54" } },
                        [
                          _c(
                            "span",
                            { staticClass: "primary--text font-weight-bold" },
                            [_vm._v(_vm._s(_vm.serviceInitial))]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "font-weight-bold title text-center mt-3 primary--text",
                    },
                    [_vm._v(" " + _vm._s(_vm.service.name) + " ")]
                  ),
                  _c("span", { staticClass: "service-card__underline mt-3" }),
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "7" } }, [
                _c("p", { staticClass: "text-xl-justify" }, [
                  _vm._v(" " + _vm._s(_vm.service.description) + " "),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { justify: "end" } },
            _vm._l(_vm.service.views, function (view, i) {
              return _c(
                "v-btn",
                {
                  key: i,
                  staticClass: "ml-3",
                  attrs: { rounded: "", color: "primary", href: view.view_url },
                },
                [_vm._v("as " + _vm._s(view.view_name))]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }