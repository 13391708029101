// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".service-card {\n  width: 613px;\n  box-shadow: 3px 3px 4px rgba(91, 92, 94, 0.1);\n  border: 1px solid rgba(0, 0, 0, 0.125) !important;\n  border-radius: 0.5rem;\n}\n.service-card__underline {\n  background-color: #0099dc;\n  width: 100px;\n  height: 2px;\n  display: inline-block;\n}", ""]);
// Exports
exports.locals = {
	"primary": "#0099dc",
	"orange": "#FC6222",
	"decline": "#ff4848"
};
module.exports = exports;
